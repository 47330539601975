import logo from './logo.svg';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import "react-datepicker/dist/react-datepicker.css";


import './css/App.css';
import './css/Custom.css';
import HeadNav from './components/HeadNav';
import Sidebar from './components/Sidebar';
import { useEffect } from 'react';
import { useState } from 'react';
import Login from './components/Login';
import { observer } from 'mobx-react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { GetLocalItem } from './uti/uti';
import Store from './mobx/store';

import {
  createBrowserRouter,
  RouterProvider,
  useNavigate,
} from "react-router-dom";
import Dashboard from './screens/Dashboard';
import Settings from './screens/Settings';
import Users from './screens/Users';
import Grounds from './screens/Grounds';
import AddUpdateGround from './screens/AddUpdateGround';
import ManageGround from './screens/ManageGround';
import Bookings from './screens/Bookings';
import BookingPyaments from './screens/BookingPyaments';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Dashboard />,
  },
  {
    path: "/grounds",
    element: <Grounds />,
  },
  {
    path: "/create-ground",
    element: <AddUpdateGround />,
  },
  {
    path: "/update-ground/:slug",
    element: <AddUpdateGround />,
  },
  {
    path: "/manage-ground/:slug",
    element: <ManageGround />,
  },
  {
    path: "/users",
    element: <Users />,
  },
  {
    path: "/bookings",
    element: <Bookings />,
  },
  {
    path: "/bookings/:bookingId/payments",
    element: <BookingPyaments />,
  },
  {
    path: "/settings",
    element: <Settings />,
  },
  {
    path: "/login",
    element: <Login />,
  },
]);

const App = observer(() => {

  const [sideBarActive, SetsideBarActive] = useState(false)

  console.log("sideBarActive", sideBarActive) 

  // const LoadLocalAuth = async () => {

  //   const localAuth = await GetLocalItem("auth")

  //   console.log("localAuth", localAuth)

  //   if(localAuth){
  //     Store.auth = localAuth
  //   }

  // }

  useEffect(() => {

    // LoadLocalAuth()

  }, [])

  

  // if(!Store.auth.token){
  //   return (
  //     <>
  //       <Login />
  //       <ToastContainer />
  //     </>
  //   )
  // }

  return (
    <>
      {/* <Sidebar sideBarActive={sideBarActive} />
      <section className="home-section">
        <HeadNav onPressSideBarBtn={() => SetsideBarActive(!sideBarActive)} />
        <div className="home-content">
          <RouterProvider router={router}  />
        </div>
      </section> */}
      <RouterProvider router={router}  />
      <ToastContainer />
    </>

  );
})

export default App;
