
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import {
  createBrowserRouter,
  Link,
  RouterProvider,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { CallAuthDelete, CallAuthGet, CallAuthPost, CallAuthPut } from '../Services/lib';
import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';
import Badge from 'react-bootstrap/Badge';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import { AllBookingStatus, AllPaymentStatus, RemoveEmptyDataFromObj, ShowConfirm } from '../uti/uti';
import moment from 'moment';
import ReactPaginate from 'react-paginate';
import SearchUserModal from '../components/SearchUserModal';
import InnerLayer from '../components/InnerLayer';
import SingleGround from '../components/SingleGround';

const BookingPyaments = observer(() => {

  const params = useParams()

  const [submitData, setsubmitData] = useState(null)
  const [ele, setdata] = useState({})

  const handleClose = () => setsubmitData(null);

  

  useEffect(() => {

    LoadData()

  }, [])

  const LoadData = async () => {

    const res = await CallAuthGet(`/ground-bookings/${params?.bookingId}`)

    console.log(res)

    if(res){
      setdata(res.result)
    }

  }

  const UpdateStatus = async (orderId, status) => {

    console.log(orderId, status)
    const res = await CallAuthPost(`/ground-booking/update-status`, {
      _id:orderId,
      bookingStatus:status
    })
    if(res){
      LoadData()
    }
  }

  const UpdatePaymentStatus = async (orderId, status) => {
    const res = await CallAuthPost(`/ground-booking/update-status`, {
      _id:orderId,
      paymentStatus:status
    })
    if(res){
      LoadData()
    }
  }

  return (
    <InnerLayer>
    <div className='screenContainer' >
      <div className='mb-2' >
        <Stack direction="horizontal" gap={2} >
          <h4 className='mb-0' >BOOKING DETAILS</h4>

          {/* <Button onClick={() => setshowProviderSearchModal(true)} size="sm" variant="primary">
            {searchData.provider ? `Selected Ground: ${selectedGround?.phone || searchData.ground}` : "Select Ground"}
          </Button>
          {searchData.provider &&
            <Button size="sm" onClick={() => UpdateSearchData({ground:null})} variant="outline-danger">
              X
            </Button>
          } */}

          {/* <Button variant="primary" onClick={() => setsubmitData({})} >All</Button> */}
        </Stack>
      </div>
      <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col">Ground Info</th>
            <th scope="col">Booking Info</th>
            <th scope="col">Booking By</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          {ele ? 
            <tr>
              <td style={{maxWidth:300}} >
                <SingleGround 
                  hideRightPanel={true}
                  ele={ele?.ground}
                />
              </td>
              <td>
                <h6>Start: {moment(ele?.startDate).format("Do MMMM YYYY -- hh:mm A")}</h6>
                <h6>End: {moment(ele?.endDate).format("Do MMMM YYYY -- hh:mm A")}</h6>
                <h6>Total amount: <b>{ele?.totalCharge}</b></h6>
                <h6>Total Paid: <b>{ele?.chargePaid || 0}</b></h6>
                <h6>Booking Status: <b>{ele?.bookingStatus}</b></h6>
                <h6>Payment Status: <b>{ele?.paymentStatus}</b></h6>
                <h6>Payment Method: <b>{ele?.paymentMethod}</b></h6>
                {ele?.cancelReason ? <h6>Total: {ele?.serviceLocation?.palce} {ele?.serviceLocation?.details} </h6> : null }
              </td>
              <td>
                <h6>{ele?.bookedBy?.name}</h6>
                <h6>{ele?.bookedBy?.phone}</h6>
              </td>
              <td>
                <h6>Booking Status</h6>
                <Form.Group>
                  <Form.Select onChange={(e) => UpdateStatus(ele._id, e.target.value)}>
                    {/* "pending", "userCancelled", "adminCancelled", "confirmed", "onTheWay", "onDuty", "done" */}
                    {AllBookingStatus?.map((status) =>
                      <option selected={ele.bookingStatus == status} value={status} >{status}</option>
                    )}
                  </Form.Select>
                </Form.Group>
                <h6 style={{marginTop:30}} >Payment Status</h6>
                <Form.Group>
                  <Form.Select onChange={(e) => UpdatePaymentStatus(ele._id, e.target.value)}>
                    {AllPaymentStatus?.map((status) =>
                      <option selected={ele.paymentStatus == status} value={status} >{status}</option>
                    )}
                  </Form.Select>
                </Form.Group>

              </td>
            </tr>
           : null}
        </tbody>
      </table>

      <div className='mb-2' >
        <Stack direction="horizontal" gap={2} >
          <h4 className='mb-0' >PAYMENTS</h4>
        </Stack>
      </div>
      <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col">PAYMENT DATE & TIME</th>
            <th scope="col">PAYMENT DETAILS</th>
            <th scope="col">STATUS</th>
          </tr>
        </thead>
        <tbody>
            {ele?.payments?.map(innerEle => 
              <tr>
                <td>{moment(innerEle.createdAt).format("Do MMMM YYYY - hh:mm a")}</td>
                <td>
                  <h6>Method: {innerEle?.method}</h6>
                  <h6>Account Number: <b>{innerEle?.accountNumber}</b></h6>
                  <h6>Transaction Id: <b>{innerEle?.transactionId}</b></h6>
                  <h6>Amount: <b>{innerEle?.amount}</b></h6>
                </td>
                <td>
                  <h6>STATUS: <b>{innerEle?.status}</b></h6>
                  <h6>PAYEMTN FOR: {innerEle?.paymentType}</h6>
                </td>
              </tr>
            )}
        </tbody>
      </table>

      <Modal show={submitData ? true : false} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Order Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* <SearchUserModal 
        show={assignableOrderInfo?._id ? true : false}
        handleClose={() => setassignableOrderInfo({})}
        onSelectUser={userData => {
          AssignProvider(userData?._id)
        }}
      /> */}

    </div>
    </InnerLayer>

  );
})

export default BookingPyaments;
