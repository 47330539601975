
import { observer } from 'mobx-react';
import { useEffect, useRef, useState } from 'react';
import {
  createBrowserRouter,
  Link,
  RouterProvider,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { CallAuthDelete, CallAuthGet, CallAuthPost, CallAuthPut } from '../Services/lib';
import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';
import Badge from 'react-bootstrap/Badge';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import { AllBookingStatus, AllPaymentStatus, RemoveEmptyDataFromObj, ShowConfirm } from '../uti/uti';
import moment from 'moment';
import ReactPaginate from 'react-paginate';
import SearchUserModal from '../components/SearchUserModal';
import InnerLayer from '../components/InnerLayer';
import SingleGround from '../components/SingleGround';
import DatePicker from 'react-datepicker';
import { DownloadTableExcel } from 'react-export-table-to-excel';

const Bookings = observer(() => {



  let [searchParams, setSearchParams] = useSearchParams();

  const [assignableOrderInfo, setassignableOrderInfo] = useState({})

  const [showUserSearchModal, setshowUserSearchModal] = useState(false)
  const [showProviderSearchModal, setshowProviderSearchModal] = useState(false)
  const [selectedUser, setselectedUser] = useState(null)
  const [selectedGround, setselectedGround] = useState(null)

  const [submitData, setsubmitData] = useState(null)
  const [data, setdata] = useState([])
  const [totalData, settotalData] = useState(0)
  const [loading, setloading] = useState(false)

  const tableRef = useRef(null);


  const [searchData, setsearchData] = useState({
    page:searchParams.get('page') || 1,
    paymentStatus:searchParams.get('paymentStatus'),
    bookingStatus:searchParams.get('bookingStatus'),
    ground:searchParams.get('ground'),
    bookedBy:searchParams.get('bookedBy'),
    date:searchParams.get('date'),
  })

  const handleClose = () => setsubmitData(null);

  const UpdateSearchData = (data) => {
    let newData = JSON.parse(JSON.stringify(searchData ? searchData : {}))
    newData.page = 1
    setSearchParams(RemoveEmptyDataFromObj({...newData, ...data}))
    setsearchData(newData ? {...newData, ...data} : data)
  }

  useEffect(() => {

    LoadData()

  }, [searchData])

  const LoadData = async () => {

    setloading(true)

    const {page, paymentStatus, bookingStatus, bookedBy, ground, date} = searchData

    let url = `/ground-bookings?page=${page || 1}`

    if (paymentStatus) url += `&paymentStatus=${paymentStatus}`
    if (bookingStatus) url += `&bookingStatus=${bookingStatus}`
    if (bookedBy) url += `&bookedBy=${bookedBy}`
    if (ground) url += `&ground=${ground}`
    if (date) url += `&startDate=${moment(date).startOf('day').toISOString()}&endDate=${moment(date).endOf('day').toISOString()}`

    const res = await CallAuthGet(url)

    console.log(res)

    if(res){
      setdata(res.result)
      settotalData(res.total)
    }

    setloading(false)

  }

  const AssignProvider = async (providerId) => {
    const res = await CallAuthPut(`/order-service`, {
      _id:assignableOrderInfo?._id,
      assignTo:providerId
    })
    if(res){
      LoadData()
    }
  }

  const UpdateStatus = async (orderId, status) => {

    console.log(orderId, status)
    const res = await CallAuthPost(`/ground-booking/update-status`, {
      _id:orderId,
      bookingStatus:status
    })
    if(res){
      LoadData()
    }
  }

  const UpdatePaymentStatus = async (orderId, status) => {
    const res = await CallAuthPost(`/ground-booking/update-status`, {
      _id:orderId,
      paymentStatus:status
    })
    if(res){
      LoadData()
    }
  }

  return (
    <InnerLayer>
    <div className='screenContainer' >
      <div className='mb-2' >
        <Stack direction="horizontal" gap={2} >
          <h4 className='mb-0' >BOOKINGS</h4>
          <div>
            <Form.Group>
              <Form.Select  id="disabledSelect" onChange={(e) => UpdateSearchData({bookingStatus:e.target.value})}>
                <option  value={""}>Select Booking Status</option>
                {/* "pending", "userCancelled", "adminCancelled", "confirmed", "onTheWay", "onDuty", "done" */}
                {AllBookingStatus?.map((status) =>
                  <option value={status} selected={status == searchData?.bookingStatus} >{status}</option>
                )}
              </Form.Select>
            </Form.Group>
          </div>
          <div>
            <Form.Group>
              <Form.Select  id="disabledSelect" onChange={(e) => UpdateSearchData({paymentStatus:e.target.value})}>
                <option  value={""}>Select Payment Status</option>
                {/* "pending", "userCancelled", "adminCancelled", "confirmed", "onTheWay", "onDuty", "done" */}
                {AllPaymentStatus?.map((status) =>
                  <option value={status} selected={status == searchData?.paymentStatus} >{status}</option>
                )}
              </Form.Select>
            </Form.Group>
          </div>

          

          <Button onClick={() => setshowUserSearchModal(true)} size="sm" variant="primary">
            {searchData.bookedBy ? `Selected User: ${selectedUser?.phone || searchData.bookedBy}` : "Select User"}
          </Button>
          {searchData.bookedBy &&
            <Button size="sm" onClick={() => UpdateSearchData({bookedBy:null})} variant="outline-danger">
              X
            </Button>
          }

          {/* <Button onClick={() => setshowProviderSearchModal(true)} size="sm" variant="primary">
            {searchData.provider ? `Selected Ground: ${selectedGround?.phone || searchData.ground}` : "Select Ground"}
          </Button>
          {searchData.provider &&
            <Button size="sm" onClick={() => UpdateSearchData({ground:null})} variant="outline-danger">
              X
            </Button>
          } */}

          {/* <Button variant="primary" onClick={() => setsubmitData({})} >All</Button> */}

          
          <DatePicker
            selected={searchData?.date || ""}
            onChange={(date) => UpdateSearchData({date:date})} //only when value has changed
            placeholderText='Select Date'
            dateFormat={"dd/MM/yyyy"}
          />
          {searchData.date &&
            <Button size="sm" onClick={() => UpdateSearchData({date:null})} variant="outline-danger">
              Clear Date
            </Button>
          }

          <DownloadTableExcel
              filename="Arena Ground Booking table"
              sheet="bookings"
              currentTableRef={tableRef.current}
          >

              <Button size="sm" > Export excel </Button>

          </DownloadTableExcel>
        </Stack>
      </div>
      
      {loading ?
        <div style={{margin:"100px auto", textAlign:'center'}} >
          <h2>Loading...</h2>
        </div>
      :
        <table ref={tableRef} class="table table-striped">
          <thead>
            <tr>
              <th scope="col">Ground Info</th>
              <th scope="col">Booking Info</th>
              <th scope="col">Booking By</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {data.map(ele => 
              <tr>
                <td style={{maxWidth:300}} >
                  <SingleGround 
                    hideRightPanel={true}
                    ele={ele?.ground}
                  />
                </td>
                <td>
                  <h6>Start: {moment(ele?.startDate).format("Do MMMM YYYY -- hh:mm A")}</h6>
                  <h6>End: {moment(ele?.endDate).format("Do MMMM YYYY -- hh:mm A")}</h6>
                  <h6>Total amount: <b>{ele?.totalCharge}</b></h6>
                  <h6>Total Paid: <b>{ele?.chargePaid || 0}</b></h6>
                  <h6>Booking Status: <b>{ele?.bookingStatus}</b></h6>
                  <h6>Payment Status: <b>{ele?.paymentStatus}</b></h6>
                  <h6>Payment Method: <b>{ele?.paymentMethod}</b></h6>
                  {ele?.cancelReason ? <h6>Total: {ele?.serviceLocation?.palce} {ele?.serviceLocation?.details} </h6> : null }
                </td>
                <td>
                  <h6>{ele?.bookedBy?.name}</h6>
                  <h6>{ele?.bookedBy?.phone}</h6>
                </td>
                <td>
                  <h6>Booking Status</h6>
                  <Form.Group>
                    <Form.Select onChange={(e) => UpdateStatus(ele._id, e.target.value)}>
                      {/* "pending", "userCancelled", "adminCancelled", "confirmed", "onTheWay", "onDuty", "done" */}
                      {AllBookingStatus?.map((status) =>
                        <option selected={ele.bookingStatus == status} value={status} >{status}</option>
                      )}
                    </Form.Select>
                  </Form.Group>
                  <h6 style={{marginTop:30}} >Payment Status</h6>
                  <Form.Group>
                    <Form.Select onChange={(e) => UpdatePaymentStatus(ele._id, e.target.value)}>
                      {AllPaymentStatus?.map((status) =>
                        <option selected={ele.paymentStatus == status} value={status} >{status}</option>
                      )}
                    </Form.Select>
                  </Form.Group>

                  <Link
                    to={`/bookings/${ele?._id}/payments`}
                  >
                    <Button
                      style={{marginTop:10}}
                    >
                      See Payments
                    </Button>
                  </Link>

                </td>
              </tr>
            )}
          </tbody>
        </table>
      }

        <ReactPaginate
          breakLabel="..."
          nextLabel="next >"
          onPageChange={(e) => UpdateSearchData({page:e?.selected + 1})}
          // onPageChange={(e) => console.log(e)}
          pageRangeDisplayed={5}
          initialPage={parseInt(searchData.page) - 1 || 0}
          pageCount={totalData/20}
          previousLabel="< previous"
          renderOnZeroPageCount={null}

          marginPagesDisplayed={2}
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakClassName="page-item"
          breakLinkClassName="page-link"
          containerClassName="pagination"
          activeClassName="active"
        />

      <Modal show={submitData ? true : false} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Order Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <SearchUserModal 
        show={showUserSearchModal}
        handleClose={setshowUserSearchModal}
        onSelectUser={userData => {
          setselectedUser(userData)
          UpdateSearchData({bookedBy:userData._id})
        }}
      />

      <SearchUserModal 
        show={showProviderSearchModal}
        handleClose={setshowProviderSearchModal}
        onSelectUser={userData => {
          setselectedGround(userData)
          UpdateSearchData({provider:userData._id})
        }}
      />

      {/* <SearchUserModal 
        show={assignableOrderInfo?._id ? true : false}
        handleClose={() => setassignableOrderInfo({})}
        onSelectUser={userData => {
          AssignProvider(userData?._id)
        }}
      /> */}

    </div>
    </InnerLayer>

  );
})

export default Bookings;
